let recruitmentRoutes = [
  // profile
  // {
  // 	path: '/profile',
  // 	name: 'user-profile-2',
  // 	component: () =>
  // 		import(/* webpackChunkName: "profile" */ '@/views/profile/Profile.vue'),
  // },

  // organization
  {
    path: '/recruitments/organizations',
    name: 'recruitments-organizations',
    component: () =>
      import(
        /* webpackChunkName: "recruitments-organizations" */ '@/views/recruitments/organizations/Organizations.vue'
      ),
    meta: { role: ['Admin', 'Recruiter'] }
  },
  {
    path: '/recruitments/organizations/create',
    name: 'recruitments-organizations-create',
    component: () =>
      import(
        /* webpackChunkName: "recruitments-organizations-create" */ '@/views/recruitments/organizations/Create.vue'
      ),
    meta: { role: ['Admin', 'Recruiter'] }
  },
  {
    path: '/recruitments/organizations/:id',
    name: 'recruitments-organizations-details',
    component: () =>
      import(
        /* webpackChunkName: "recruitments-organizations-details" */ '@/views/recruitments/organizations/Details.vue'
      ),
    meta: { role: ['Admin', 'Recruiter'] }
  },

  // companies
  {
    path: '/recruitments/companies',
    name: 'recruitments-companies',
    component: () =>
      import(
        /* webpackChunkName: "components-tabs" */ '@/views/recruitments/companies/Companies.vue'
      ),
    meta: { role: ['Admin', 'Recruiter'] }
  },
  {
    path: '/recruitments/companies/create',
    name: 'recruitments-companies-create',
    component: () =>
      import(
        /* webpackChunkName: "recruitments-organizations-create" */ '@/views/recruitments/companies/Create.vue'
      ),
    meta: { role: ['Admin', 'Recruiter'] }
  },
  {
    path: '/recruitments/companies/:id',
    name: 'recruitments-companies-details',
    component: () =>
      import(
        /* webpackChunkName: "recruitments-organizations-details" */ '@/views/recruitments/companies/Details.vue'
      ),
    meta: { role: ['Admin', 'Recruiter'] }
  },

  // business units
  {
    path: '/recruitments/business-units',
    name: 'recruitments-business-units',
    component: () =>
      import(
        /* webpackChunkName: "recruitments-business-units" */ '@/views/recruitments/business-units/BusinessUnits.vue'
      ),
    meta: { role: ['Admin', 'Recruiter'] }
  },
  {
    path: '/recruitments/business-units/create',
    name: 'recruitments-business-units-create',
    component: () =>
      import(
        /* webpackChunkName: "recruitments-business-units-create" */ '@/views/recruitments/business-units/Create.vue'
      ),
    meta: { role: ['Admin', 'Recruiter'] }
  },
  {
    path: '/recruitments/business-units/:id',
    name: 'recruitments-business-units-details',
    component: () =>
      import(
        /* webpackChunkName: "recruitments-business-units-details" */ '@/views/recruitments/business-units/Details.vue'
      ),
    meta: { role: ['Admin', 'Recruiter'] }
  },

  // depratments
  {
    path: '/recruitments/departments',
    name: 'recruitments-departments',
    component: () =>
      import(
        /* webpackChunkName: "recruitments-departments" */ '@/views/recruitments/departments/Departments.vue'
      ),
    meta: { role: ['Admin', 'Recruiter'] }
  },
  {
    path: '/recruitments/departments/create',
    name: 'recruitments-departments-create',
    component: () =>
      import(
        /* webpackChunkName: "recruitments-departments-create" */ '@/views/recruitments/departments/Create.vue'
      ),
    meta: { role: ['Admin', 'Recruiter'] }
  },
  {
    path: '/recruitments/departments/:id',
    name: 'recruitments-departments-details',
    component: () =>
      import(
        /* webpackChunkName: "recruitments-departments-detais" */ '@/views/recruitments/departments/Details.vue'
      ),
    meta: { role: ['Admin', 'Recruiter'] }
  },

  // jobs
  {
    path: '/recruitments/jobs',
    name: 'recruitments-jobs',
    component: () =>
      import(
        /* webpackChunkName: "components-tabs" */ '@/views/recruitments/jobs/Jobs.vue'
      )
  },
  {
    path: '/recruitments/jobs/create',
    name: 'recruitments-jobs-create',
    component: () =>
      import(
        /* webpackChunkName: "components-tabs" */ '@/views/recruitments/jobs/CreateOrEdit.vue'
      ),
    meta: { role: ['Admin', 'Recruiter', 'Hiring Manager'] }
  },
  {
    path: '/recruitments/jobs/:id',
    name: 'recruitments-jobs-details',
    component: () =>
      import(
        /* webpackChunkName: "components-tabs" */ '@/views/recruitments/jobs/Details.vue'
      )
  },
  {
    path: '/recruitments/jobs/:id/edit',
    name: 'recruitments-jobs-edit',
    component: () =>
      import(
        /* webpackChunkName: "components-tabs" */ '@/views/recruitments/jobs/CreateOrEdit.vue'
      ),
    meta: { role: ['Admin', 'Recruiter', 'Hiring Manager'] }
  },

  // candidates
  {
    path: '/recruitments/candidates',
    name: 'recruitments-candidates',
    component: () =>
      import(
        /* webpackChunkName: "components-tabs" */ '@/views/recruitments/candidates/Candidates.vue'
      ),
    meta: { role: ['Admin', 'Recruiter', 'Vendor'] }
  },
  {
    path: '/recruitments/candidates/create',
    name: 'recruitments-candidates-create',
    component: () =>
      import(
        /* webpackChunkName: "components-tabs" */ '@/views/recruitments/candidates/CreateOrEdit.vue'
      ),
    meta: { role: ['Admin', 'Recruiter', 'Vendor'] }
  },
  {
    path: '/recruitments/candidates/:id',
    name: 'recruitments-candidates-details',
    component: () =>
      import(
        /* webpackChunkName: "components-tabs" */ '@/views/recruitments/candidates/Details.vue'
      )
    // meta: { role: ['Admin', 'Recruiter'] },
  },
  {
    path: '/recruitments/candidates/:id/edit',
    name: 'recruitments-candidates-edit',
    component: () =>
      import(
        /* webpackChunkName: "components-tabs" */ '@/views/recruitments/candidates/CreateOrEdit.vue'
      ),
    meta: { role: ['Admin', 'Recruiter', 'Vendor'] }
  },

  // applicants
  {
    path: '/recruitments/applicants',
    name: 'recruitments-applicants',
    component: () =>
      import(
        /* webpackChunkName: "components-tabs" */ '@/views/recruitments/applicants/Applicants.vue'
      )
  },

  // onboarding documents
  {
    path: '/recruitments/onboarding-documents',
    name: 'recruitments-onboarding-documents',
    component: () =>
      import(
        /* webpackChunkName: "onboarding-tabs" */ '@/views/recruitments/onboarding-documents/OnboardingDocuments.vue'
      )
  }
];

export default recruitmentRoutes;
